import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TableViewModel } from '@components/models/table';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { SwitchService } from '@services/switch.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Vehicle } from '@/models/vehicle';
import { WialonService } from '@services/wialon.service';
import { Subscription, timer } from 'rxjs';
import { typeOf } from 'uri-js/dist/esnext/util';
import { MinTransporte } from 'assets/images/MinTransporte';
import { superIT } from 'assets/images/superIT';
import { Account } from '@/models/account';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var wialonUnits: [];

declare function init1vehiculo(unitvalue: any): any;

@Component({
    selector: 'app-create-fuec',
    templateUrl: './create-fuec.component.html',
    styleUrls: ['./create-fuec.component.scss']
})
export class CreateFuecComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    account: Account;
    responsibles: any;
    routes: any;
    contractors: any;
    drivers: any;
    units: any;
    drivers_count: number;
    wiaUnitsLoaded: boolean = false;
    wiaUnits: [] = [];
    show_modal: boolean = false;
    show_view_contractor: boolean = false;
    show_view_route: boolean = false;
    show_view_responsible: boolean = false;
    vehicle: Vehicle;
    route_name: string;
    route_description: string;
    route_origin_destination: string;
    route_origin: string;
    route_destination: string;
    contractor_name: string;
    contractor_nit: string;
    contract_object: string;
    sess: any;
    token: string;
    super_token: string;
    wialon_user_name: string;
    soatDate: Date;
    endDate: Date;
    tecnomecanicaDate: Date;
    voDate: Date;
    code_hab: any;
    resolution_fuec: any;

    driversModel: TableViewModel = {
        // title: 'Conductores',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            { title: 'Nombre', key: 'name', filter: false, data: [] },
            {
                title: 'Cédula',
                key: 'cc',
                filter: false,
                data: []
            },
            {
                title: 'Licencia',
                key: 'licencia',
                filter: false,
                data: []
            },
            {
                title: 'Vencimiento',
                key: 'vencimiento',
                filter: false,
                data: []
            }
        ],
        records: [],
        totalRecords: [],
        showFilters: false,
        enableActions: false,
        removeCard: false
    };

    public fuecForm: FormGroup;
    route = new FormControl();
    vehiculo = new FormControl();
    responsables = new FormControl();
    date_hab: string;
    current_month: string = new Date(Date.now()).getMonth().toString();
    current_year: string = new Date(Date.now()).getFullYear().toString();
    minDate: string = this.current_year + '-01-01';
    maxDate: string =
        this.current_month == '11'
            ? (new Date(Date.now()).getFullYear() + 1).toString() + '-12-31'
            : this.current_year + '-12-31';
    fuec_contract_number: string;
    contract_number: string;
    start_date: any;
    end_date: any;
    drivers_selected: any = [];
    drivers_ids: any = [];
    drivers_fuec: any = [];

    fuec_serie: any = [];
    private suscription: Subscription;

    @ViewChild('pdfTable') pdfTable: ElementRef;

    constructor(
        private appService: AppService,
        private apiService: ApiService,
        private cd: ChangeDetectorRef,
        private modalService: SwitchService,
        private toastr: ToastrService,
        private router: Router,
        private _WialonService: WialonService
    ) {
        const timer$ = timer(100, 20000);
        this.suscription = timer$.subscribe(() => this.periodicUpdateData());
    }

    ngOnInit(): void {
        this.loadNextContractNumber();
        this.fuecForm = new FormGroup({
            contract_number: new FormControl(null, Validators.required),
            contractor_id: new FormControl(null, Validators.required),
            responsible_ids: new FormControl(null, Validators.required),
            contract_object: new FormControl(null, Validators.required),
            route_id: new FormControl(null, Validators.required),
            agreement: new FormControl('no-aplica', Validators.required),
            agreement_desc: new FormControl(''),
            date_start: new FormControl(null, Validators.required),
            date_end: new FormControl(null, Validators.required),
            vehicle_id: new FormControl(null, Validators.required),
            driver_ids: new FormControl(null, Validators.required),
            token: new FormControl(this.token),
            w_uname: new FormControl(this.wialon_user_name)
        });

        this.endDate = new Date(this.fuecForm.value.date_end);
        this.appService.getAccountInfo().then((account) => {
            var date = new Date(account.fuec_fecha_hab);
            var year = date.getFullYear();
            var yearStr = year.toString();
            var hb = yearStr.slice(yearStr.length - 2, yearStr.length);
            this.token = account.token;
            this.super_token = account.super_token;
            this.wialon_user_name = account.name;
            this.date_hab = hb;
            this.fuec_serie = this.zfill(account.fuec_end_number + 1, 4);
            this.initWialon();
            this.code_hab = account.fuec_code_hab;
            this.resolution_fuec = account.fuec_resolucion;
            this.account = account;
        });
        this.apiService
            .getDriversFuecs(this.currentPage, this.pageSize, this.searchby)
            .then((drivers) => {
                this.drivers = drivers.result;
                this.drivers_count = drivers.total_count;
                this.driversModel.records = this.drivers;
                this.driversModel.pagination = {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    pageCount: drivers.count,
                    rowCount: this.drivers_count
                };
            });
        this.getRoutes();
        this.getContractors();
        this.getResponsibles();
        this.modalService.$modal.subscribe((value) => {
            this.show_modal = value;
        });
    }

    loadNextContractNumber() {
        this.apiService
            .getLastContractNumber()
            .then((response) => {
                if (response && response.next_contract_number) {
                    this.fuecForm.patchValue({
                        contract_number: response.next_contract_number
                    });
                }
            })
            .catch((error) => {
                console.error('Error al cargar el número de contrato:', error);
            });
    }

    periodicUpdateData() {
        // let existUnits = wialonUnits || false
        this.syncUnits();
        if (typeof wialonUnits === 'object') {
            // if (!this.wiaUnitsLoaded) {
            //   this.syncUnits(); // <<<---using ()=> syntax
            //   this.wiaUnitsLoaded = true;
            // }
        }
        this.cd.detectChanges();
    }

    getContractors() {
        this.apiService.getContractorsFuecs().then((contractors) => {
            this.contractors = contractors.result;
            if (this.show_modal) {
                this.closeAllModals();
            }
            this.cd.detectChanges();
        });
    }

    getResponsibles() {
        this.apiService.getResponsiblesFuecs().then((responsibles) => {
            this.responsibles = responsibles.result;
            if (this.show_modal) {
                this.closeAllModals();
            }
            this.cd.detectChanges();
        });
    }

    getRoutes() {
        this.apiService.getRoutesFuecs().then((routes) => {
            this.routes = routes.result;
            if (this.show_modal) {
                this.closeAllModals();
            }
            this.cd.detectChanges();
        });
    }

    formatFuecSeries(): string {
        const separate = this.fuec_contract_number.split('');
        let num1 = '';
        for (var i = 0; i <= 2; i++) {
            num1 = `${num1}${separate[i]}`;
        }
        let num2 = '';
        for (var i = 3; i <= 6; i++) {
            num2 = `${num2}${separate[i]}`;
        }
        let num3 = '';
        for (var i = 7; i <= 8; i++) {
            num3 = `${num3}${separate[i]}`;
        }
        let num4 = '';
        for (var i = 9; i <= 12; i++) {
            num4 =
                this.current_year ===
                    this.fuecForm.value.date_start.substring(0, 4)
                    ? this.current_year
                    : String(Number.parseInt(this.current_year) + 1);
        }
        let num5 = '';
        for (var i = 13; i <= 16; i++) {
            num5 = `${num5}${separate[i]}`;
        }
        let num6 = '';
        for (var i = 17; i <= 20; i++) {
            num6 = `${num6}${separate[i]}`;
        }
        return `${num1} ${num2} ${num3} ${num4} ${num5} ${num6}`;
    }

    // character limiter function

    insertLineBreaks(text, maxLength) {
        let result = '';
        for (let i = 0; i < text.length; i += maxLength) {
            result += text.slice(i, i + maxLength) + '\n';
        }
        return result.trim();
    }


    validateNumber(event: any): void {
      const input = event.target;
      input.value = input.value.replace(/[^0-9]/g, '');
  }


    openPDF() {
        var json_drivers = [];
        const emailText = this.insertLineBreaks(this.account.email, 35);
        json_drivers = this.drivers_fuec.map((driver) => {
            const data = [
                {
                    text: driver.name,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.cc,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.licencia,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.vencimiento,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {}
            ];

            return data;
        });
        var json_responsibles = [];
        json_responsibles = this.responsables.value.map((responsible) => {
            const data = [
                {
                    text: responsible.fullname,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: responsible.doc_number,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: responsible.address,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: responsible.phone,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {}
            ];

            return data;
        });
        var docDefinition = {
            pageMargins: [40, 90, 40, 60],
            header: {
                columns: [
                    {
                        image: MinTransporte,
                        width: 260,
                        height: 60,
                        alignment: 'left',
                        opacity: 1,
                        margin: [40, 10]
                    },
                    {
                        image: `data:image/jpeg;base64,${this.account.logo}`,
                        width: 100,
                        height: 70,
                        alignment: 'right',
                        opacity: 0.9,
                        fit: [100, 70],
                        margin: [-25, 15]
                    }
                ],
                columnGap: 80
            },
            content: [
                {
                    style: 'tableExample',
                    table: {
                        body: [
                            [
                                {
                                    text: [
                                        `FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO \n PÚBLICO DE TRANSPORTE TERRESTRE AUTOMOTOR ESPECIAL \n NO. `,
                                        {
                                            text: this.formatFuecSeries(),
                                            color: '#FE0000'
                                        }
                                    ],
                                    colSpan: 24,
                                    alignment: 'center'
                                },
                                {},
                                {},
                                {},
                                {},
                                {}, //
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Razon social: ',
                                            bold: true
                                        },
                                        this.account.razonsocial
                                    ],
                                    colSpan: 14
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'NIT: ',
                                            bold: true
                                        },
                                        this.account.id_document
                                    ],
                                    colSpan: 10
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Contrato No. ',
                                            bold: true
                                        },
                                        this.fuecForm.value.contract_number
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Contratante: ',
                                            bold: true
                                        },
                                        this.contractor_name
                                    ],
                                    colSpan: 14
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'NIT: ',
                                            bold: true
                                        },
                                        this.contractor_nit
                                    ],
                                    colSpan: 10
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Objeto del contrato: ',
                                            bold: true
                                        },
                                        this.contract_object
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ], [
                                {
                                    text: [
                                        {
                                            text: 'Ruta: ',
                                            bold: true
                                        },
                                        this.route_name
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Descripcion: ',
                                            bold: true
                                        },
                                        this.route_description
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Origen - Destino: ',
                                            bold: true
                                        },
                                        (this.route_origin && this.route_destination)
                                            ? `${this.route_origin} - ${this.route_destination}`
                                            : (this.route_origin || this.route_destination)
                                                ? `${this.route_origin || this.route_name} - ${this.route_destination || this.route_name}`
                                                : this.route_name
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Convenio \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement == 'cv'
                                            ? this.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Consorcio \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement == 'cs'
                                            ? this.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Union temporal \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement == 'ut'
                                            ? this.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Otro \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement ==
                                            'no-aplica'
                                            ? 'no-aplica'
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Vigencia del contrato:',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Desde: \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.date_start
                                    ],
                                    alignment: 'center',
                                    colSpan: 12
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Hasta: \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.date_end
                                    ],
                                    alignment: 'center',
                                    colSpan: 12
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Características del vehículo',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Placa',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Modelo',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Marca',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Clase',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Interno',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Operación',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: this.vehicle.registration_plate
                                        ? this.vehicle.registration_plate
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.vehicle.year
                                        ? this.vehicle.year
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.vehicle.brand
                                        ? this.vehicle.brand
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.vehicle.model
                                        ? this.vehicle.model
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text:
                                        this.vehicle && this.vehicle.vin
                                            ? this.vehicle.vin
                                            : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.vehicle.noperacion
                                        ? this.vehicle.noperacion
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Conductores',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Nombres y Apellidos',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Identificación',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Licencia',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Vigencia',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            //drivers
                            ...json_drivers,
                            [
                                {
                                    text: [
                                        {
                                            text: 'Responsables',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Nombres y Apellidos',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Identificacion',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Dirección',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Teléfono',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            //responsibles
                            ...json_responsibles,
                            [
                                {
                                    qr: `https://fuec.monitoringinnovation.com/fuec/${this.fuec_contract_number}`,
                                    alignment: 'center',
                                    fit: 100,
                                    colSpan: 6,
                                    margin: [0, 10]
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: `${this.account.razonsocial} \n ${this.account.address} \n ${this.account.mobile} \n ${emailText}`,
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    image: superIT,
                                    width: 80,
                                    alignment: 'center',
                                    colSpan: 6,
                                    margin: [0, 5]
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    image: `data:image/jpeg;base64,${this.account.signature}`,
                                    width: 90,
                                    alignment: 'center',
                                    colSpan: 6,
                                    margin: [0, 5]
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ]
                        ]
                    }
                },
                {
                    text: 'INSTRUCTIVO PARA LA DETERMINACION DEL NUMERO CONSECUTIVO DEL FUEC',
                    pageBreak: 'before',
                    alignment: 'center',
                    fontSize: 14,
                    bold: true
                },
                {
                    margin: [25, 10],
                    columns: [
                        [
                            {
                                text: 'El Formato Unico de Extracto del Contrato "FUEC" estará constituido por los siguientes números:',
                                fontSize: 10,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'a) Los tres primeros dígitos de izquierda a derecha correcponderán al código de la Dirección Territorial que otorgó la habilitación o de aquella a la cual se hubiese trasladado la Empresa de Servicio público de Transporte Terrestre Automotor Especial;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Antioquia-Choco',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '305'
                                    },
                                    {
                                        width: '*',
                                        text: 'Huila-Caquetá ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '441'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Atlántico ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '208'
                                    },
                                    {
                                        width: '*',
                                        text: 'Magdalena',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '247'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Bolívar-San Andrés y Providencia',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '213'
                                    },
                                    {
                                        width: '*',
                                        text: 'Meta-Vaupés-Vichada ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '550'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Boyacá-Casanare',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '415'
                                    },
                                    {
                                        width: '*',
                                        text: 'Nariño-Putumayo',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '352'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Caldas',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '317'
                                    },
                                    {
                                        width: '*',
                                        text: 'N/Santander-Arauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '454'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '319'
                                    },
                                    {
                                        width: '*',
                                        text: 'Quindio',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '363'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'César',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '220'
                                    },
                                    {
                                        width: '*',
                                        text: 'Risaralda',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '366'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Córdoba-Sucre',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '223'
                                    },
                                    {
                                        width: '*',
                                        text: 'Santander',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '468'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cundinamarca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '425'
                                    },
                                    {
                                        width: '*',
                                        text: 'Tolima',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '473'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Guajira',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '241'
                                    },
                                    {
                                        width: '*',
                                        text: 'Valle del Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '376'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                ul: [
                                    'b) Los cuatro dígitos siguientes señalarán el número de resolución mediante la cual se otorgó la habilitación de la Empresa. En caso que la resolución no tenga estos dígitos, los faltantes serán completados con ceros a la izquierda;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'c) Los dos siguientes dígitos, corresponderán a los dos últimos del año en que la empresa fue habilitada;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'd) Acontinuación, cuatro dígitos que corresponderán al año en el que se expide el extracto del contrato;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `e) Posteriormente, cuatro dígitos que identifican el número del contrato. La numeración debe ser consecutiva, establecida por cada empresa y continuará con la numeración dada a los contratos de prestación del servicios celebrados para el transporte de estudiantes, empleados, turistas, usuarios del servicio de salud, grupos específicos de usuarios, en vigencia de la resolución 3068 de 2014.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `f) Finalmente, los cuatro últimos dígitos corresponderán al número consecutivo del extracto de contrato que se expida para la ejecución de cada contrato. Se debe expedir un nuevo extracto por vencimiento del plazo inicial del mismo o por cambio del vehículo.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: 'EJEMPLO:',
                                fontSize: 10,
                                bold: true
                            },
                            {
                                text: `Empresa habilitada por la Dirección Territorial Cundinamarca en el año 2012 con resolución de habilitación No. 0155, que expide el primer extracto del contrato en el año 2015, del contrato 255. El número del Formato Unico de Extracto del Contrato "FUEC" será : 425015512201502550001.
                                `,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                fontSize: 7,
                                columns: [
                                    {
                                        text: `Nota:`,
                                        width: 25,
                                        bold: true
                                    },
                                    {
                                        text: `El vehiculo se encuentra en perfecto estado Mecánico y de aseo.`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Para verificar la autenticidad de esta planilla consultar la pagina web https://fuec.monitoringinnovation.com/fuec/${this.fuec_contract_number}.`,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: `INFORMACION POR RUTA ESTIMADA`,
                                fontSize: 7,
                                bold: true,
                                alignment: 'center',
                                margin: [0, 7]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Distancia recorrida estimada`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Duracion total estimada `,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Peajes`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Consumo de combustible estimado`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Resol. 315/2013 Art. PROTOCOLO DE ALISTAMIENTO DIARIO,Se deja constancia en este documento que participaron del proceso de alistamiento diaro el(los) conductor(es) mencionado(s) en este FUEC bajo la supervicion de la empresa a travéz deusos tecnológicos amparados por la ley 1450, art. 230 y del decreto ley 019 de 2012, art. 4`,
                                fontSize: 7
                            }
                        ]
                    ]
                }
            ]
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#8a8a8a';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                }
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }

    zfill(number: string, width: number): string {
      let numberOutput = number.toString();
      let length = numberOutput.length;
      let zero = '0';

      if (width <= length) {
          return numberOutput;
      } else {
          return zero.repeat(width - length) + numberOutput;
      }
  }


    onChangeContractNumber() {
      let contractNumber = this.fuecForm.value.contract_number.toString();
      let length = contractNumber.length;

      if (length > 4) {
          this.contract_number = contractNumber.slice(length - 4, length);
      } else {
          this.contract_number = this.zfill(contractNumber, 4);
      }

      this.onChangeFuecContract();
  }


    onChangeResponsable() {
        if (this.responsables.value.length <= 1) {
            this.fuecForm.controls['responsible_ids'].setValue(
                this.responsables.value[0].id
            );
        } else {
            this.responsables.setValue(this.fuecForm.value.responsible_ids);
        }
    }

    onChangeObjContract() {
        switch (this.fuecForm.value.contract_object) {
            case '1':
                this.contract_object =
                    'Contrato para transporte de estudiantes';
                break;
            case '2':
                this.contract_object =
                    'Contrato para transporte de empleados y/o contratistas';
                break;
            case '3':
                this.contract_object = 'Contrato para transporte de turistas';
                break;
            case '4':
                this.contract_object = `Contrato para un grupo específico de usuarios (Transporte de
                    particulares)`;
                break;
            case '5':
                this.contract_object = `Contrato para transporte de usuarios del servicio de salud`;
                break;
            case '6':
                this.contract_object = `Contrato para transporte empresarial para prensa, cine y television`;
                break;
        }
    }

    onChangeContractorName() {
        var contractorName = this.contractors.find(
            (contractor: any) =>
                parseInt(contractor.id) ===
                parseInt(this.fuecForm.value.contractor_id)
        );

        this.contractor_name = contractorName.company_name;
        this.contractor_nit = contractorName.doc_number;
    }

    onChangeRoute() {
        var route = this.routes.find(
            (route: any) =>
                parseInt(route.id) === parseInt(this.fuecForm.value.route_id)
        );
        this.route_name = route.name
        this.route_description = route.description
        this.route_origin = route.origin
        this.route_destination = route.destination
    }

    formatDate(dateF) {
        const fecha = dateF;
        const year = fecha.getFullYear();
        const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const day = fecha.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    onChangeVehicle() {
        this.vehicle = this.units.find(
            (unit: any) => unit.name === this.fuecForm.value.vehicle_id
        );
        this.endDate = new Date(this.fuecForm.value.date_end);
        this.soatDate = new Date(
            Number.parseInt(this.vehicle.soat.toString()) * 1000
        );
        this.soatDate.setFullYear(this.soatDate.getFullYear() + 1);
        this.vehicle.soat = this.soatDate;
        this.tecnomecanicaDate = new Date(
            Number.parseInt(this.vehicle.tecnomecanica.toString()) * 1000
        );
        this.tecnomecanicaDate.setFullYear(
            this.tecnomecanicaDate.getFullYear() + 1
        );
        this.vehicle.tecnomecanica = this.tecnomecanicaDate;
        console.log(this.vehicle.voperacion);
        this.voDate = new Date(
            Number.parseInt(this.vehicle.voperacion.toString()) * 1000
        );
        console.log(this.voDate);
        this.voDate.setFullYear(this.voDate.getFullYear() + 2);
        console.log(this.voDate);
        this.vehicle.voperacion = this.voDate;
        console.log(this.vehicle.voperacion);
        // this.vehicle = vehicle;
        this.cd.detectChanges();
    }

    onChangeDateEnd() {
        this.endDate = new Date(this.fuecForm.value.date_end);
        this.soatDate = this.vehicle.soat;
        this.tecnomecanicaDate = this.vehicle.tecnomecanica;
        this.voDate = this.vehicle.voperacion;
        this.cd.detectChanges();
    }

    setUnitInfo(unit: Vehicle) {
        let data = init1vehiculo(unit.id_wialon);
        unit.registration_plate = data.registration_plate;
        unit.brand = data.brand;
        unit.model = data.model;
        unit.type = data.vehicle_type;
        unit.vin = data.vin;
        unit.soat = data.soat;
        this.soatDate = data.soat;
        unit.tecnomecanica = data.tecnomecanica;
        unit.noperacion = data.noperacion;
        unit.voperacion = data.voperacion;
        this.apiService
            .updateUnitInterval(
                unit.id_wialon.toString(),
                unit.voperacion.toString(),
                unit.noperacion.toString(),
                unit.soat.toString(),
                unit.tecnomecanica.toString()
            )
            .then((res) => { });
        this.vehicle = unit;
    }

    async initWialon() {
        await this._WialonService.Carga(['vehiculos']);
        // esperamos un segundo y leemos wialonUnit
    }

    syncUnits() {
        // this.apiService
        //     .updateWialonFuecUnits(this.token, wialonUnits)
        //     .then((res) => {});
        this.apiService.getUnitsFuecs().then((units) => {
            this.units = units.result;
            this.wiaUnitsLoaded = true;
        });
    }

    onChangeFuecContract() {
        var fuec_contract =
            this.code_hab +
            this.resolution_fuec +
            this.date_hab +
            this.current_year +
            this.contract_number +
            this.fuec_serie;

        this.fuec_contract_number = fuec_contract;

        console.log("CONTRATO: " + fuec_contract)
    }

    // Creation of Fuec

    createFuec() {
        this.onChangeContractNumber();
        if (
            this.fuecForm.valid &&
            this.vehicle &&
            this.vehicle.soat &&
            this.vehicle.tecnomecanica &&
            this.vehicle.soat > this.endDate &&
            this.vehicle.tecnomecanica > this.endDate &&
            this.vehicle.voperacion > this.endDate
        ) {
            var fuec = {
                contract_number: this.fuecForm.value.contract_number,
                fuec_series: this.fuec_contract_number,
                contractor_id: parseInt(this.fuecForm.value.contractor_id),
                responsible_ids: [this.fuecForm.value.responsible_ids],
                contract_object: this.fuecForm.value.contract_object,
                route_id: parseInt(this.fuecForm.value.route_id),
                agreement: this.fuecForm.value.agreement,
                agreement_desc: this.fuecForm.value.agreement_desc,
                date_start: this.fuecForm.value.date_start,
                date_end: this.fuecForm.value.date_end,
                vehicle_id: this.vehicle.id,
                driver_ids: this.fuecForm.value.driver_ids,
                account: this.account
            };

            this.apiService
                .createFuec(fuec)
                .then((response) => {
                    if (response.msg) {
                        this.openPDF();
                        this.router.navigate(['/history']);
                    } else {
                        this.toastr.error(
                            'Ocurrio un error en la creacion del fuec.'
                        );
                    }
                })
                .catch((error) => {
                    console.error('Error al crear fuec:', error);
                    this.toastr.error('Error en la validación');
                });
        } else {
            var textError = 'Falta';
            if (!this.fuecForm.controls['contract_number'].valid) {
                textError = textError + ', numero de contrato';
            }
            if (!this.fuecForm.controls['contractor_id'].valid) {
                textError = textError + ', contratista';
            }
            if (!this.fuecForm.controls['responsible_ids'].valid) {
                textError = textError + ', responsable';
            }
            if (!this.fuecForm.controls['contract_object'].valid) {
                textError = textError + ', objeto de contrato';
            }
            if (!this.fuecForm.controls['route_id'].valid) {
                textError = textError + ', ruta';
            }
            if (!this.fuecForm.controls['date_start'].valid) {
                textError = textError + ', fecha inicial';
            }
            if (!this.fuecForm.controls['date_end'].valid) {
                textError = textError + ', fecha final';
            }
            if (!this.fuecForm.controls['agreement'].valid) {
                textError = textError + ', tipo de convenio';
            }
            if (!this.fuecForm.controls['vehicle_id'].valid) {
                textError = textError + ', vehiculo';
            }
            if (!this.vehicle) {
                textError = textError + ', el vehiculo';
            }
            if (!this.vehicle.soat) {
                textError = textError + ', soat de vehiculo';
            }
            if (!this.vehicle.tecnomecanica) {
                textError = textError + ', tecnomecacina de vehicuo';
            }
            if (
                this.vehicle.soat < this.fuecForm.value.date_end ||
                this.vehicle.tecnomecanica < this.fuecForm.value.date_end ||
                this.vehicle.voperacion < this.fuecForm.value.date_end
            ) {
                textError =
                    textError +
                    ', el vehiculo no cumple las fechas establecidas';
            }
            this.toastr.error(textError);
        }
    }

    paginatorEvent(currentPage: number) {
        this.apiService
            .getDriversFuecs(currentPage, this.pageSize, this.searchby)
            .then((drivers) => {
                if (drivers.result) {
                    this.drivers = drivers.result;
                    this.drivers_count = drivers.total_count;
                    this.driversModel.records = this.drivers;
                    this.driversModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: drivers.count,
                        rowCount: this.drivers_count
                    };
                    this.cd.detectChanges();
                } else {
                    this.logout();
                }
            })
            .catch((e) => { });
    }

    selectDriver(i: any) {
        var exist = this.drivers_selected.find((index) => index === i);
        var driver = this.drivers.find((drive, index) => index === i);
        if (this.fuecForm.value.date_end && this.fuecForm.value.date_start) {
            if (!exist && exist !== 0) {
                if (driver.vencimiento > this.fuecForm.value.date_end) {
                    if (this.drivers_selected.length < 3) {
                        this.drivers_selected.push(i);
                        this.drivers_fuec.push(driver);
                        this.drivers_ids.push(driver.name);
                    } else {
                        this.toastr.error(
                            'Lo siento, no puede seleccionar mas de tres conductores'
                        );
                    }
                } else {
                    this.toastr.error(
                        'Este conductor no se puede seleccionar ya que su pase vence antes de la fecha de terminacion del fuec'
                    );
                }
            } else {
                this.removeItemFromArr(this.drivers_selected, i);
                this.removeItemFromArr(this.drivers_fuec, driver);
                this.drivers_ids = this.drivers_ids.filter(
                    (d) => d !== driver.name
                );
            }
        } else {
            this.toastr.error(
                'Lo siento no puede hacer esto, debe primero seleccionar la fecha del fuec'
            );
        }
        this.fuecForm.controls['driver_ids'].setValue(this.drivers_ids);
    }

    openModalCreateContractor() {
        this.modalService.$modal.emit(true);
        this.show_view_contractor = true;
    }

    openModalCreateResponsible() {
        this.modalService.$modal.emit(true);
        this.show_view_responsible = true;
    }

    openModalCreateRoute() {
        this.modalService.$modal.emit(true);
        this.show_view_route = true;
    }

    closeAllModals() {
        this.modalService.$modal.emit(false);
        this.show_view_contractor = false;
        this.show_view_responsible = false;
        this.show_view_route = false;
    }

    removeItemFromArr(arr: any, item: any) {
        var i = arr.indexOf(item);

        if (i !== -1) {
            arr.splice(i, 1);
        }
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }

    logout() {
        this.appService.logout();
    }

    get agreement() {
        return this.fuecForm.value.agreement;
    }

    get agreement_desc() {
        return this.fuecForm.value.agreement_desc;
    }

    get date_start() {
        return this.fuecForm.value.date_start;
    }

    get date_end() {
        return this.fuecForm.value.date_end;
    }
}
