import {ChangeDetectorRef, Component, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Field, TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {SwitchService} from '@services/switch.service';
import {ToastrService} from 'ngx-toastr';
import {EventEmitter} from '@angular/core';
import internal from 'stream';

@Component({
    selector: 'app-manage-contractors',
    templateUrl: './manage-contractors.component.html',
    styleUrls: ['./manage-contractors.component.scss']
})
export class ManageContractorsComponent implements OnInit {
    emitEvent = new EventEmitter();
    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    contractors: any;
    contractors_count: any;
    contractorsModel: TableViewModel = {
        title: 'Contratistas',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'fullname', filter: false, data: []},
            {title: 'NIT', key: 'doc_number', filter: false, data: []},
            {title: 'Direccion', key: 'address', filter: false, data: []},
            {title: 'Telefono', key: 'phone', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };
    showModal: boolean;
    showEdit: boolean;
    showEditInfo: boolean;
    showDelete: boolean;
    contractorsForm: FormGroup;
    fuec_contractor: string;
    id_contractor: number;
    related_fuecs: [] = [];

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private md1: SwitchService,
        private ts: ToastrService
    ) {}

    ngOnInit(): void {
        this.getContractors();
        this.contractorsForm = new FormGroup({
            company_name: new FormControl(null, Validators.required),
            nit: new FormControl(null, Validators.required),
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required)
        });
        this.md1.$modal.subscribe((value) => {
            this.showModal = value;
        });
        this.md1.EditInfo.subscribe((value) => {
            this.showEditInfo = value;
        });
    }

    getContractors() {
        this.appService
            .getContractorsFuecs(this.currentPage, this.pageSize, this.searchby)
            .then((contractors) => {
                if (contractors.result) {
                    this.contractors = contractors.result;
                    this.contractors_count = contractors.total_count;
                    this.contractorsModel.records = this.contractors;
                    this.contractorsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: contractors.count,
                        rowCount: this.contractors_count
                    };
                    console.log(this.contractors);
                }
            });
    }

    showDetailInfo(info) {
        if (this.showModal) {
            this.md1.$modal.emit(false);
            this.showEdit = false;
        } else {
            this.md1.$modal.emit(true);
            this.showDelete = false;
            this.showEdit = true;
            this.id_contractor = info.id;
            this.contractorsForm.controls['company_name'].setValue(
                info.company_name
            );
            this.contractorsForm.controls['name'].setValue(info.fullname);
            this.contractorsForm.controls['nit'].setValue(info.doc_number);
            this.contractorsForm.controls['email'].setValue(info.email);
            this.contractorsForm.controls['city'].setValue(info.city);
            this.contractorsForm.controls['address'].setValue(info.address);
            this.contractorsForm.controls['phone'].setValue(info.phone);
        }
    }

    showDetailInfoEdit() {
        if (this.contractorsForm.valid) {
            if (!this.showEditInfo) {
                this.infofuecsedit();
                this.md1.EditInfo.emit(true);
                this.showEditInfo = true;
            } else {
                this.md1.EditInfo.emit(false);
                this.showEditInfo = false;
            }
        } else {
            this.ts.error('Debe llenar todos los campos');
        }
    }

    showDetailInfoDelete(info) {
        if (this.showModal) {
            this.md1.$modal.emit(false);
            this.showDelete = false;
        } else {
            this.id_contractor = info.id;
            this.infofuecsedit();
            this.md1.$modal.emit(true);
            this.showDelete = true;
            this.showEdit = false;
        }
    }

    cancelDelete() {
        this.md1.$modal.emit(false);
        this.showDelete = false;
    }

    logout() {
        this.appService.logout();
    }

    editContract() {
        if (this.contractorsForm.valid) {
            var params = {
                id: this.id_contractor,
                company_name: this.contractorsForm.value.company_name,
                nit: this.contractorsForm.value.nit,
                name: this.contractorsForm.value.name,
                email: this.contractorsForm.value.email,
                address: this.contractorsForm.value.address,
                city: this.contractorsForm.value.city,
                phone: this.contractorsForm.value.phone
            };
            this.apiService.udapteContractor(params).then((contractor) => {
                if (contractor.msg) {
                    this.ts.success('El contratista se edito con éxito');
                    this.emitEvent.emit();
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la creacion del contratista'
                    );
                }
                this.getContractors();
                this.md1.$modal.emit(false);
                this.md1.EditInfo.emit(false);
                this.id_contractor = null;
            });
        }
    }

    onRemoveContractor() {
        this.apiService.deleteContractor(this.id_contractor).then(() => {
            this.getContractors();
            this.md1.$modal.emit(false);
            this.showDelete = false;
            this.id_contractor = null;
        });
    }

    paginatorEvent(currentPage: number) {
        this.appService
            .getContractorsFuecs(currentPage, this.pageSize, this.searchby)
            .then((contractors) => {
                if (contractors.result) {
                    this.contractors = contractors.result;
                    this.contractors_count = contractors.total_count;
                    this.contractorsModel.records = this.contractors;
                    this.contractorsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: contractors.count,
                        rowCount: this.contractors_count
                    };
                    console.log(this.contractors);
                }
            });
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }

    infofuecsedit() {
        this.apiService
            .getRelatedFuecs('contractor_id', this.id_contractor)
            .then((fuecs) => {
                if (fuecs.result) {
                    this.related_fuecs = fuecs.result;
                }
                console.log(this.related_fuecs);
            });
    }
}
