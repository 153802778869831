import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {ExcelService} from '@services/excel.service';

@Component({
    selector: 'app-manage-fuec',
    templateUrl: './manage-fuec.component.html',
    styleUrls: ['./manage-fuec.component.scss']
})
export class ManageFuecComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    fuecs: any;
    fuecs_count: any;
    fuecsModel: TableViewModel = {
        title: 'FUECS',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Folio', key: 'folio', filter: false, data: []},
            {title: 'Serie', key: 'fuec_series', filter: false, data: []},
            {
                title: 'Vehiculo',
                key: 'vehicle',
                subkey: 'name',
                subobject: true,
                filter: false,
                data: []
            },
            {
                title: 'Contratista',
                key: 'contractor',
                subkey: 'contractor_name',
                subobject: true,
                filter: false,
                data: []
            }
        ],
        records: [],
        totalRecords: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };
    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private excelService: ExcelService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.appService
            .getHistoryFuecs(this.currentPage, this.pageSize, this.searchby)
            .then((fuecs) => {
                if (fuecs.result) {
                    this.fuecs = fuecs.result;
                    this.fuecs_count = fuecs.total_count;
                    this.fuecsModel.records = this.fuecs;
                    this.fuecsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: fuecs.count,
                        rowCount: this.fuecs_count
                    };
                }
            });
    }

    getTotalHistory(header: any) {
        this.appService.getHistoryFuecs().then(async (fuecs) => {
            if (fuecs.result) {
                this.fuecsModel.totalRecords = fuecs.result;
                var data = {
                    title: header.title,
                    header: header.header,
                    data: []
                };
                for await (let record of fuecs.result) {
                    var row = [];
                    for await (let key of header.keys) {
                        row.push(record[key]);
                    }
                    data.data.push(row);
                }
                await this.excelService.generateExcel(data);
            }
        });
    }

    logout() {
        this.appService.logout();
    }

    goFuecDetail(fuec) {
        // var fuec_serie = fuec.fuec_series.replace(/ /g, '');
      var fuec_serie = fuec.fuec_series;
        this.router.navigate([`/fuec/${fuec_serie}`]);
    }

    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.appService
            .getHistoryFuecs(currentPage, this.pageSize, this.searchby)
            .then((fuecs) => {
                if (fuecs.result) {
                    this.fuecs = fuecs.result;
                    this.fuecs_count = fuecs.total_count;
                    this.fuecsModel.records = this.fuecs;
                    this.fuecsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: fuecs.count,
                        rowCount: this.fuecs_count
                    };
                    this.cd.detectChanges();
                } else {
                    this.logout();
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        console.log(searchby);
        this.paginatorEvent(this.currentPage);
    }
}
