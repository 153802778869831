import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public responsibles: any = null;
    public responsibles_count: any = null;
    public routes: any = null;
    public routes_count: any = null;
    public contractors: any = null;
    public contractors_count: any = null;
    public drivers: any = null;
    public drivers_count: any = null;
    public units: any = null;
    public units_count: any = null;
    public fuecs: any = null;
    public fuecs_count: any = null;

    constructor(private http: HttpClient) {}

    // Returns an observable
    async createFuec(fuec: any) {
        console.log(fuec);

        let postFuec = await this.http
            .post<any>('/api/fuec/fuec/new', {
                jsonrpc: '2.0',
                params: fuec
            })
            .toPromise();
        let response;
        if (!postFuec.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: postFuec.error
            };
            return response;
        }
    }

    // Validation of previous contracts

    async getLastContractNumber() {
      let response = await this.http
          .get<any>('/api/fuec/fuec/last_contract_number')
          .toPromise();
      return response;
  }

    async createContractor(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/contractors/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async createResponsible(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/responsibles/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }

    async createRoutes(params: any) {
        let post = await this.http
            .post<any>('/api/fuec/routes/new', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }


    async updateWialonFuecUnits(token: string, units: any) {
        let unitsData = await this.http
            .post<any>('/api/fuec/update/wiaUnits', {
                jsonrpc: '2.0',
                params: {
                    token: token,
                    units: units
                }
            })
            .toPromise();
        if (!unitsData.error) {
            this.units = unitsData.result.result;
            this.units_count = unitsData.result.count;
            return unitsData.result;
        } else {
            return null;
        }
    }
    async updateUnitInterval(
        unit_id: string,
        voperacion: string,
        noperacion: string,
        soat: string,
        tecnomecanica: string
    ) {
        let date_voperacion = new Date(voperacion);
        let date_soat = new Date(soat);
        let date_tecnomecanica = new Date(tecnomecanica);
        let requesData = await this.http
            .get<any>(
                `/api/fuec/update/services/${unit_id}/intervals/${date_voperacion.getTime()}&${noperacion}&${date_soat.getTime()}&${date_tecnomecanica.getTime()}`
            )
            .toPromise();
        if (!requesData.error) {
            return requesData;
        } else {
            return null;
        }
    }

    async getFuecDetail(url: string) {
        let fuec = await this.http
            .get<any>(`/api/fuec/fuec/detail/${url}`)
            .toPromise();
        if (!fuec.error) {
            return fuec;
        } else {
            return null;
        }
    }

    async getResponsiblesFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let responsibles = await this.http
            .get<any>(
                `/api/fuec/responsibles/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!responsibles.error) {
            this.responsibles = responsibles.result;
            this.responsibles_count = responsibles.count;
            return responsibles;
        } else {
            return null;
        }
    }

    async getRoutesFuecs(page?: number, pageSize?: number, searchby?: string) {
        let routes = await this.http
            .get<any>(
                `/api/fuec/routes/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!routes.error) {
            this.routes = routes.result;
            this.routes_count = routes.count;
            return routes;
        } else {
            return null;
        }
    }

    async getContractorsFuecs(
        page?: number,
        pageSize?: number,
        searchby?: string
    ) {
        let contractors = await this.http
            .get<any>(
                `/api/fuec/contractors/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!contractors.error) {
            this.contractors = contractors.result;
            this.contractors_count = contractors.count;
            return contractors;
        } else {
            return null;
        }
    }

    async getDriversFuecs(page?: number, pageSize?: number, searchby?: string) {
        let drivers = await this.http
            .get<any>(
                `/api/fuec/drivers/${page ? `page=${page}` : ''}${
                    pageSize ? `&pagesize=${pageSize}` : ''
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!drivers.error) {
            this.drivers = drivers.result;
            this.drivers_count = drivers.count;
            return drivers;
        } else {
            return null;
        }
    }

    async getUnitsFuecs() {
        let units = await this.http.get<any>(`/api/fuec/units`).toPromise();
        console.log(units);
        if (!units.error) {
            this.units = units.result;
            this.units_count = units.count;
            return units;
        } else {
            return null;
        }
    }

    async getRelatedFuecs(field?, id?) {
        let fuecs = await this.http
            .get<any>(`/api/fuec/relatedfuecs/field=${field}&id=${id}`)
            .toPromise();
        if (!fuecs.error) {
            this.fuecs = fuecs.result;
            this.fuecs_count = fuecs.count;
            return fuecs;
        } else {
            return null;
        }
    }

    async udapteContractor(params) {
        let post = await this.http
            .post<any>('/api/fuec/contractors/update', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }
    async deleteContractor(id) {
        let fuecs = await this.http
            .get<any>(`/api/fuec/contractors/delete/id=${id}`)
            .toPromise();
        if (!fuecs.error) {
            this.fuecs = fuecs.result;
            return fuecs;
        } else {
            return null;
        }
    }
    async udapteResponsible(params) {
        let post = await this.http
            .post<any>('/api/fuec/responsibles/update', {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        let response;
        if (!post.error) {
            response = {
                msg: 'okay'
            };

            return response;
        } else {
            response = {
                error: post.error
            };
            return response;
        }
    }
    async deleteResponsible(id) {
        let fuecs = await this.http
            .get<any>(`/api/fuec/responsibles/delete/id=${id}`)
            .toPromise();
        if (!fuecs.error) {
            this.fuecs = fuecs.result;
            return fuecs;
        } else {
            return null;
        }
    }
}
