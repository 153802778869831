import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TableViewModel } from "@components/models/table";
import { ApiService } from "@services/api.service";
import { AppService } from "@services/app.service";

@Component({
  selector: 'app-manage-routes',
  templateUrl: './manage-routes.component.html',
  styleUrls: ['./manage-routes.component.scss']
})
export class ManageRoutesComponent implements OnInit {

  currentPage: number = 1;
  pageSize: number = 5;
  searchby: string = '';
  routes: any;
  routes_count: any;
  routesModel: TableViewModel = {
    title: "Rutas",
    description: '',
    filter: {
      page: 1,
      pageSize: 5
    },
    fields: [
      { title: 'Nombre', key: 'name', filter: false, data: [] },
      { title: 'Descripción', key: 'description', filter: false, data: [] },
      { title: 'Origen', key: 'origin', filter: false, data: [] },
      { title: 'Destino', key: 'destination', filter: false, data: [] }
    ],
    records: [],
    showFilters: true,
    enableActions: false,
    removeCard: true
  };

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.appService
      .getRoutesFuecs(this.currentPage, this.pageSize, this.searchby)
      .then((routes) => {
        if (routes.result) {
          this.routes = routes.result.map((route: any) => {
            return {
              ...route,
              origin: route.origin || 'No hay origen',
              destination: route.destination || 'No hay destino'
            };
          });
          this.routes_count = routes.total_count;
          this.routesModel.records = this.routes;
          this.routesModel.pagination = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            pageCount: routes.count,
            rowCount: this.routes_count
          };
          console.log(this.routes);
        }
      });
  }

  logout() {
    this.appService.logout();
  }

  onRemove(index: number) {
    console.log('remove event!');
  }

  paginatorEvent(currentPage: number) {
    this.appService
      .getRoutesFuecs(currentPage, this.pageSize, this.searchby)
      .then((routes) => {
        if (routes.result) {
          this.routes = routes.result.map((route: any) => {
            return {
              ...route,
              origin: route.origin || 'No hay origen',
              destination: route.destination || 'No hay destino'
            };
          });
          this.routes_count = routes.total_count;
          this.routesModel.records = this.routes;
          this.routesModel.pagination = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            pageCount: routes.count,
            rowCount: this.routes_count
          };
          console.log(this.routes);
        }
      });
  }

  pageSizeEvent(pageSize: number) {
    this.pageSize = pageSize;
    this.paginatorEvent(this.currentPage);
  }

  searchEvent(searchby: string) {
    this.searchby = searchby;
    this.paginatorEvent(this.currentPage);
  }
}
