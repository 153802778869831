import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Account } from '@/models/account';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    public fuecs: any = null;
    public fuecs_count: any = null;
    public responsibles: any = null;
    public responsibles_count: any = null;
    public contractors: any = null;
    public contractors_count: any = null;
    public routes: any = null;
    public routes_count: any = null;
    public account: any = null;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private http: HttpClient
    ) { }

    async loginByAuth({ email, password }) {
        try {
            let body = {
                jsonrpc: '2.0',
                params: {
                    login: email,
                    password: password,
                    db: 'plataforma_motion'
                }
            };

            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });

            let data = await this.http
                .post<any>('/api/fuec/auth/', body, { headers: headers })
                .toPromise();
            if ('error' in data) {
                this.toastr.error(data.error.data.message);
            } else {
                if ('error' in data.result) {
                    this.toastr.error(data.result.error.data.message);
                } else if (data.result.uid > 0) {
                    // localStorage.setItem('sessionId', data.result);
                    await this.saveUserInfo(data.result);
                    this.router.navigate(['/']);
                } else {
                    this.toastr.error('Algo salio mal, intentalo de nuevo.');
                }
                //
            }
            // const token = await Gatekeeper.loginByAuth(email, password);
            // localStorage.setItem('token', token);
            await this.getProfile();
            await this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async getProfile() {
        try {
            this.user = await this.http
                .get<any>('/api/fuec/session')
                .toPromise();
            if (!this.user) {
                this.logout();
            }
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    async logout() {
        let res = await this.http.get<any>('/api/fuec/logout').toPromise();
        this.user = null;
        await this.router.navigate(['/login']);
    }

    async getSessionInfo() {
        let user = await this.http.get<any>('/api/fuec/session').toPromise();
        if (user.uid != null) {
            return user;
        } else {
            return null;
        }
    }

    async getAccountInfo() {
        let account = await this.http.get<any>('/api/fuec/account').toPromise();
        if (!account.error) {
            this.setLocalAccount(account);
            return account;
        } else {
            return null;
        }
    }

    setLocalAccount(account: Account) {
        this.account = account;
    }

    async updateAccountInfo(account: Account) {
        let updatedAccount = await this.http
            .post<any>('/api/fuec/account/update', {
                jsonrpc: '2.0',
                params: account
            })
            .toPromise();
        if (!updatedAccount.error) {
            this.setLocalAccount(account);
            return account;
        } else {
            return null;
        }
    }
    // Returns an observable
    async uploadLogo(fileb64: string) {
        let updatedLogo = await this.http
            .post<any>('/api/fuec/update/logo', {
                jsonrpc: '2.0',
                params: {
                    logo: fileb64
                }
            })
            .toPromise();
        if (!updatedLogo.error) {
            return null;
        } else {
            return null;
        }
    }

    // Returns an observable
    async uploadSignature(fileb64: string) {
        let updatedLogo = await this.http
            .post<any>('/api/fuec/update/signature', {
                jsonrpc: '2.0',
                params: {
                    signature: fileb64
                }
            })
            .toPromise();
        if (!updatedLogo.error) {
            return null;
        } else {
            return null;
        }
    }

    // Returns an observable
    async uploadIso(fileb64: string) {
        let updatedIso = await this.http
            .post<any>('/api/fuec/update/iso', {
                jsonrpc: '2.0',
                params: {
                    iso_img: fileb64
                }
            })
            .toPromise();
        if (!updatedIso.error) {
            return null;
        } else {
            return null;
        }
    }

    async getHistoryFuecs(page?: number, pageSize?: number, searchby?: string) {
        const url = `/api/fuec/history/${page ? `page=${page}` : ``}${pageSize ? `&pagesize=${pageSize}` : ``}${searchby ? `&searchby=${searchby}` : ''}`;
        console.log(`Request URL: ${url}`);

        let fuecs = await this.http
            .get<any>(
                `/api/fuec/history/${page ? `page=${page}` : ``}${pageSize ? `&pagesize=${pageSize}` : ``
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        console.log(`Response:`, fuecs);
        if (!fuecs.error) {
            this.fuecs = fuecs.result;
            this.fuecs_count = fuecs.count;
            return fuecs;
        } else {
            console.log("ENTRO");
            return null;
        }
    }

    async getResponsiblesFuecs(
        page: number,
        pageSize: number,
        searchby?: string
    ) {
        let responsibles = await this.http
            .get<any>(
                `/api/fuec/responsibles/page=${page}&pagesize=${pageSize}${searchby ? `&searchby=${searchby}` : ''
                }`
            )
            .toPromise();
        if (!responsibles.error) {
            this.responsibles = responsibles.result;
            this.responsibles_count = responsibles.count;
            return responsibles;
        } else {
            return null;
        }
    }
    async getContractorsFuecs(
        page: number,
        pageSize: number,
        searchby?: string
    ) {
        let contractors = await this.http
            .get<any>(
                `/api/fuec/contractors/page=${page}&pagesize=${pageSize}${searchby ? `&searchby=${searchby}` : ''
                }`
            )
            .toPromise();
        if (!contractors.error) {
            this.contractors = contractors.result;
            this.contractors_count = contractors.count;
            return contractors;
        } else {
            return null;
        }
    }

    async getRoutesFuecs(page: number, pageSize: number, searchby?: string) {
        let routes = await this.http
            .get<any>(
                `/api/fuec/routes/page=${page}&pagesize=${pageSize}${searchby ? `&searchby=${searchby}` : ''
                }`
            )
            .toPromise();
        if (!routes.error) {
            this.routes = routes.result;
            this.routes_count = routes.count;
            return routes;
        } else {
            return null;
        }
    }

    private saveUserInfo(result) {
        this.user = result;
    }
}
