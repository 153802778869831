import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {EventEmitter} from '@angular/core';

@Component({
    selector: 'app-manage-responsibles',
    templateUrl: './manage-responsibles.component.html',
    styleUrls: ['./manage-responsibles.component.scss']
})
export class ManageResponsiblesComponent implements OnInit {
    emitEvent = new EventEmitter();
    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    responsibles: any;
    responsibles_count: any;
    responsiblesModel: TableViewModel = {
        title: 'Responsables',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'fullname', filter: false, data: []},
            {title: 'NIT', key: 'doc_number', filter: false, data: []},
            {title: 'Direccion', key: 'address', filter: false, data: []},
            {title: 'Telefono', key: 'phone', filter: false, data: []}
        ],
        records: [],
        showFilters: false,
        enableActions: true
    };
    showModal: boolean = false;
    showEdit: boolean;
    showEditInfo: boolean;
    showDelete: boolean;
    responsible_selected: any;
    responsiblesForm: FormGroup;
    fuec_responsible: string;
    id_responsible: number;
    related_fuecs: [] = [];

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private md1: SwitchService,

        private ts: ToastrService
    ) {}

    ngOnInit(): void {
        this.getResponsibles();
        this.responsiblesForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            id_type: new FormControl(null, Validators.required),
            nit: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            phone: new FormControl(null, Validators.required)
        });
        this.md1.$modal.subscribe((value) => {
            this.showModal = value;
        });

        this.md1.EditInfo.subscribe((value) => {
            this.showEditInfo = value;
        });
    }

    getResponsibles() {
        this.appService
            .getResponsiblesFuecs(
                this.currentPage,
                this.pageSize,
                this.searchby
            )
            .then((responsibles) => {
                if (responsibles.result) {
                    this.responsibles = responsibles.result;
                    this.responsibles_count = responsibles.total_count;
                    this.responsiblesModel.records = this.responsibles;
                    this.responsiblesModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: responsibles.count,
                        rowCount: this.responsibles_count
                    };
                    console.log(this.responsibles);
                }
            });
    }

    logout() {
        this.appService.logout();
    }

    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.appService
            .getResponsiblesFuecs(currentPage, this.pageSize, this.searchby)
            .then((responsibles) => {
                if (responsibles.result) {
                    this.responsibles = responsibles.result;
                    this.responsibles_count = responsibles.total_count;
                    this.responsiblesModel.records = this.responsibles;
                    this.responsiblesModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: responsibles.count,
                        rowCount: this.responsibles_count
                    };
                    console.log(this.responsibles);
                }
            });
    }

    showDetailInfo(info) {
        if (this.showModal) {
            this.md1.$modal.emit(false);
            this.showEdit = false;
        } else {
            this.md1.$modal.emit(true);
            this.showDelete = false;
            this.showEdit = true;
            this.id_responsible = info.id;
            this.responsible_selected = info;
            this.responsiblesForm.controls['name'].setValue(info.fullname);
            this.responsiblesForm.controls['id_type'].setValue(info.id_type);
            this.responsiblesForm.controls['nit'].setValue(info.doc_number);
            this.responsiblesForm.controls['email'].setValue(info.email);
            this.responsiblesForm.controls['city'].setValue(info.city);
            this.responsiblesForm.controls['address'].setValue(info.address);
            this.responsiblesForm.controls['phone'].setValue(info.phone);
        }
    }
    showDetailInfoEdit() {
        if (this.responsiblesForm.valid) {
            if (!this.showEditInfo) {
                this.infofuecsedit();
                this.md1.EditInfo.emit(true);
                this.showEditInfo = true;
            } else {
                this.md1.EditInfo.emit(false);
                this.showEditInfo = false;
            }
        } else {
            this.ts.error('Debe llenar todos los campos');
        }
    }
    showDetailInfoDelete(info) {
        if (this.showModal) {
            this.md1.$modal.emit(false);
            this.showDelete = false;
        } else {
            this.id_responsible = info.id;
            this.md1.$modal.emit(true);
            this.infofuecsedit();
            this.showDelete = true;
            this.showEdit = false;
        }
    }
    cancelDelete() {
        this.md1.$modal.emit(false);
        this.showDelete = false;
    }
    editResponsible() {
        if (this.responsiblesForm.valid) {
            console.log(this.responsiblesForm.valid);
            var params = {
                id: this.id_responsible,
                id_type: this.responsiblesForm.value.id_type,
                id_document: this.responsiblesForm.value.nit,
                name: this.responsiblesForm.value.name,
                email: this.responsiblesForm.value.email,
                address: this.responsiblesForm.value.address,
                city: this.responsiblesForm.value.city,
                phone: this.responsiblesForm.value.phone
            };
            this.apiService.udapteResponsible(params).then((contractor) => {
                if (contractor.msg) {
                    this.ts.success('El responsable se edito con éxito');
                    this.emitEvent.emit();
                } else {
                    this.ts.error(
                        'Lo siento, hubo un problema en la creacion del contratista'
                    );
                }
                this.getResponsibles();
                this.md1.$modal.emit(false);
                this.md1.EditInfo.emit(false);
                this.id_responsible = null;
            });
        }
    }
    onRemoveResponsible() {
        this.apiService.deleteResponsible(this.id_responsible).then(() => {
            this.getResponsibles();
            this.md1.$modal.emit(false);
            this.showDelete = false;
            this.id_responsible = null;
        });
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }
    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
    infofuecsedit() {
        this.apiService
            .getRelatedFuecs('responsible_ids', this.id_responsible)
            .then((fuecs) => {
                if (fuecs.result) {
                    this.related_fuecs = fuecs.result;
                }
                console.log(this.related_fuecs);
            });
    }
}
