<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
    <div class="row">
        <div class="align-items-center justify-content-center">
            <div class="acionts-content">
                <div
                    matTooltip="Saldo"
                    class="icon-td"
                    (click)="goFuecDetail($implicit)"
                >
                    <svg
                        version="1.1"
                        id="listos"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        style="enable-background: new 0 0 100 100"
                        xml:space="preserve"
                        class="table-icon"
                    >
                        <path
                            fill="current"
                            d="M74,84H26v-8h48V84z M74,56H26v8h48V56z M58,36v8h16v-8H58z M58,16v8h16v-8H58z M58,0v8h24v84H18V48h-8v52h80V0
                H58z M42,19.3L29.3,32H42V19.3 M50,0v40H10L50,0L50,0z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<app-table
    [model]="fuecsModel"
    [removeCard]="false"
    (paginatorEvent)="paginatorEvent($event)"
    (pageSizeEvent)="pageSizeEvent($event)"
    (searchEvent)="searchEvent($event)"
    (getRecords)="getTotalHistory($event)"
    [hideIndex]="true"
    [actionsTemplate]="actionsTemplate"
></app-table>
